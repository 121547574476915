import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page.js";
import montySigniture from '../images/monty-signiture.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`The Vimy Oaks Legacy Corporation (VOLC) volunteers and partners`}</strong>{` are
recognized below. I am grateful for the contribution of our volunteers and
partners who embraced our projects in France and Canada and gave unselfishly of
their time. For some, it was over 5 years.`}</p>
    <p><strong parentName="p">{`Management Team`}</strong></p>
    <ul>
      <li parentName="ul">{`Monty McDonald, President and CEO, Repatriation Project Manager`}</li>
      <li parentName="ul">{`Colonel (Retired) Ralph Coleman, Director Government, Media, and Public
Relations`}</li>
      <li parentName="ul">{`Robert Domoney, VP Logistics `}{`&`}{` Systems and Manager of Trees for Canada
Sales`}</li>
      <li parentName="ul">{`Robin McDonald, Director Legal and Secretary`}</li>
      <li parentName="ul">{`James Wakefield, Treasurer and CFO`}</li>
    </ul>
    <p><strong parentName="p">{`Partners`}</strong></p>
    <ul>
      <li parentName="ul">{`The Vimy Foundation (TVF): Christopher Sweeney (Chairman), Jeremy Diamond
(Executive Director), Caitlin Baily (Executive Director)`}</li>
      <li parentName="ul">{`Scarborough Chinese Baptist Church: Scott Bissell (Outreach Pastor), Philip
Jeung (Facilities Manager), Deacons Board`}</li>
    </ul>
    <p><strong parentName="p">{`Governments`}</strong></p>
    <ul>
      <li parentName="ul">{`Canada: John McKay, MP Scarborough Guildwood, (VOLC’s “Man on the Hill”)`}</li>
      <li parentName="ul">{`France: Mayor Pierre Senechal, Town of Givenchy en Gohelle (local community
support, zoning approvals, resource people)`}</li>
    </ul>
    <p><strong parentName="p">{`Horticulture and Forestry`}</strong></p>
    <ul>
      <li parentName="ul">{`Ed Lawrence (radio personality, advisor, connector)`}</li>
      <li parentName="ul">{`NVK Nursery team: Case Vanderkruk (Owner Manager), Caitlin Ayling (Propagation
Manager), Andrew Barbour (Nurseryman), Peter Werder (Logistics Manager tree
sales), Tracey Tigchelaar (Sales orders and Accounting)`}</li>
      <li parentName="ul">{`Les Pépinières de France: Bruno Picard (Owner Montferrat Nursery, France),
Cécile Duval (les Pépinières Sales Manager)`}</li>
      <li parentName="ul">{`Commonwealth War Graves Commission: Gareth Hardware (Director Horticulture
N.W. Europe)`}</li>
      <li parentName="ul">{`Landscape Ontario: Tony Di Giovanni (Executive Director), Paul Brydges (Past
President, Director Highway of Heroes)`}</li>
      <li parentName="ul">{`Landscape New Brunswick `}{`&`}{` PEI: Jim Landry (Executive Director,
Distribution Manager Trees for Maritimes)`}</li>
      <li parentName="ul">{`Foresters: Dr Ron Ayling, Dave Lemkay`}</li>
    </ul>
    <p><strong parentName="p">{`Project Planning Design and Construction`}</strong>{` (`}{`*`}{`denotes paid contractor)`}</p>
    <ul>
      <li parentName="ul">{`Linda Dicaire (Landscape Architect, Designer of Centennial Park)`}</li>
      <li parentName="ul">{`Dan Normandin (UXO Removal Expert)`}</li>
      <li parentName="ul">{`Patrick Rochet, Director Sécurité Civile for the Pas-de-Calais region (UXO
Removal)`}</li>
      <li parentName="ul">{`Christophe Douchet`}{`*`}{`, Director Séché Eco Services (UXO Removal)`}</li>
      <li parentName="ul">{`Alan Ward (Construction Cost Engineering)`}</li>
      <li parentName="ul">{`Ludovic Bulourde`}{`*`}{` (Project Manager, SNB Colas)`}</li>
      <li parentName="ul">{`Chantel Pradines (Advisor France landscape design standards)`}</li>
    </ul>
    <p><strong parentName="p">{`French Connection`}</strong></p>
    <ul>
      <li parentName="ul">{`Charles Nemeth (Our Man on the Ground in France trouble shooting with
contractors and government officials)`}</li>
      <li parentName="ul">{`Francoise Briche (oversight on Centennial Park site clearance and
construction, tree delivery logistics for ONF and local towns)`}</li>
      <li parentName="ul">{`Marguerite Savidant (French translation services)`}</li>
    </ul>
    <p><strong parentName="p">{`Artistic Elements`}</strong></p>
    <ul>
      <li parentName="ul">{`Marlene Hilton Moore (Sculpture of the Bugler statue Centennial Park)`}</li>
    </ul>
    <p><strong parentName="p">{`Website `}{`&`}{` Promotion`}</strong></p>
    <ul>
      <li parentName="ul">{`Andrew Thauer (design, implementation and maintenance of Website)`}</li>
      <li parentName="ul">{`Glen Loates (artwork for VOLC logo and honourarium prints)`}</li>
      <li parentName="ul">{`Michael Loates (computer graphic design of VOLC Logo)`}</li>
      <li parentName="ul">{`Sarah `}{`&`}{` Christopher Loates (design `}{`&`}{` printing of honourarium
materials)`}</li>
    </ul>
    <p><strong parentName="p">{`Special Acknowledgement`}</strong></p>
    <p>{`In heartfelt gratitude to my wife, MaryAnne, who was with me every step of the
way, being supportive, keeping me on the critical path and coming up with unique
ideas, while putting her life on hold so my dream could be fulfilled.`}</p>
    <p><strong parentName="p">{`Monty McDonald, President VOLC`}</strong></p>
    <img src={montySigniture} alt="Monty McDonald's Signiture" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      